import { useParams } from "react-router-dom";
import LogoImage from "../components/LogoImage";
import { Flex, Form, message, Spin, Typography } from "antd";
import SimCardInfo from "../components/SimCardInfo";
import Container from "../components/Container";
// import { useGetFieldsQuery } from "@/api/apiSlice";
import GetFieldsResponse from "@/schemas/GetFieldsResponse";
import ConfirmButton from "../components/ConfirmButton";
import FormInput from "../components/FormInput";
import { useEffect, useState } from "react";
import { useLazyGetFieldsQuery, useGetRedemptionDataQuery, usePostRedemptionMutation } from "../api/apiSlice";
import RedeemRequest from "@/schemas/RedeemRequest";
import ErrorResponse from "@/schemas/ErrorResponse";

const { Title, Text } = Typography;

type PageParams = {
  redemptionCode: string;
  simIdx: string;
}

const styles = {
  form: {
    flex: 1,
    width: "100%",
  }
}

export default function FormPage() {
  const { redemptionCode, simIdx } = useParams<PageParams>();
  const [messageApi, contextHolder] = message.useMessage();
  const {
    data: redemptionData,
    error: redemptionDataError,
    isLoading: redemptionDataIsLoading,
  } = useGetRedemptionDataQuery({
    orderNo: redemptionCode ?? "",
    simIdx: parseInt(simIdx ?? "0")
  });

  const [fields, setFields] = useState<GetFieldsResponse | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isJustRedeemed, setIsJustRedeemed] = useState(false);
  const [getFields] = useLazyGetFieldsQuery();
  const [submitRedemption] = usePostRedemptionMutation();

  useEffect(() => {
    async function fetchAsyncFields() {
      if (redemptionData && getFields && fields === null) {
        const { vendorId } = redemptionData;
        const { data, error, isLoading } = await getFields(vendorId);
        if (data) {
          /*
           * Prefill form fields
           */
          if (redemptionData.fields === undefined) {
            console.warn("Redemption data fields is undefined", redemptionData);
            setFields(data);
            setIsLoading(false);
            return;
          }

          for (const field of redemptionData.fields) {
            const { key, value } = field;
            if (data.fields.find(f => f.name === key)) {
              console.info("Prefilling", key, value, formData);
              setFormData({ ...formData, [key]: value });
            }
          }

          setFields(data);
          setIsLoading(false);
        }
      }
    }
    fetchAsyncFields();
  }, [redemptionData, getFields, fields]);

  // const {
  //   data: fieldData,
  //   error: fieldDataError,
  //   isLoading: fieldDataIsLoading
  // } = useGetFieldsQuery(redemptionData?.vendorId ?? "");
  // const { data, error, isLoading } = useGetFieldsQuery({ redemptionCode });

  const [formData, setFormData] = useState<Record<string, string>>({});

  // placeholder data
  // const data: GetFieldsResponse = {
  //   title: "[eSIM] - Vodafone Australia 30 Days 35GB",
  //   description: "For multiple eSIM/SIM purchases, kindly submit one set of passport details per eSIM/SIM."
  //                 + "\nThis will ensure a smoother activation process. Thank you!",
  //   imageUrl: "https://media.cnn.com/api/v1/images/stellar/prod/191011110307-02-australia-beautiful-places.jpg?q=w_2187,h_1230,x_0,y_0,c_fill/h_447",
  //   fields: [
  //     {
  //       label: "Order Number",
  //       type: "text",
  //       required: true,
  //       maxLength: 50,
  //       name: "orderNumber",
  //       prefilledValue: redemptionCode
  //     },
  //     {
  //       label: "Arrival Date in Australia",
  //       type: "date",
  //       required: true,
  //       name: "arrivalDate"
  //     },
  //     {
  //       label: "Passport Number",
  //       type: "text",
  //       required: true,
  //       maxLength: 50,
  //       name: "passportNumber"
  //     },
  //     {
  //       label: "Passport Country of Origin",
  //       type: "text",
  //       required: true,
  //       maxLength: 50,
  //       name: "passportCountry"
  //     },
  //     {
  //       label: "Given Name",
  //       type: "text",
  //       required: true,
  //       maxLength: 255,
  //       name: "givenName"
  //     },
  //     {
  //       label: "Surname",
  //       type: "text",
  //       required: true,
  //       maxLength: 255,
  //       name: "surname"
  //     },
  //     {
  //       label: "Middle Name (if any)",
  //       type: "text",
  //       required: false,
  //       maxLength: 255,
  //       name: "middleName"
  //     },
  //     {
  //       label: "Date of Birth",
  //       type: "date",
  //       required: true,
  //       name: "dob"
  //     },
  //     {
  //       label: "Email Address",
  //       type: "email",
  //       required: true,
  //       maxLength: 255,
  //       name: "email"
  //     }
  //   ]
  // }
  // const isLoading = false;

  async function handleFinish() {
    // Create redemption request
    const data: RedeemRequest = {
      orderNo: redemptionCode!!,
      simIdx: parseInt(simIdx!!),
      fields: Object.entries(formData).map(([key, value]) => ({ key, value }))
    }
    console.info("Redeem request", data);

    // Send request
    messageApi.loading("Redeeming...");

    try {
      const result = await submitRedemption(data).unwrap();
      console.info("Redeem result", result);
      setIsJustRedeemed(true);
      messageApi.destroy();
      messageApi.success("Redeemed successfully");
    } catch (error: any) {
      messageApi.destroy();
      console.error("Failed to redeem", error);
      if (error?.status === 400) {
        messageApi.error(error.data.message);
      } else {
        messageApi.error("Failed to redeem");
      }
    }
  }

  function handleSetFormData(key: string, value: string) {
    setFormData({ ...formData, [key]: value });
  }

  const isLoadingALl = redemptionDataIsLoading || isLoading;

  return (
    <Container>
      {contextHolder}
      <LogoImage />
      { redemptionDataError && <Title level={3}>Failed to load redemption data</Title> }
      { !redemptionDataError && redemptionCode && isLoadingALl
        && <Flex align="center" justify="center" flex={1}><Spin /></Flex> }
      { !redemptionDataError && redemptionCode && redemptionData?.status !== "REDEEMED" && !isLoadingALl && (
        <>
        <SimCardInfo
          title={fields!!.title ?? redemptionData?.productName ?? "Redeem"}
          imageUrl={fields!!.imageUrl}
          description={fields!!.description}
        />

        {/* { redemptionData?.status === "REDEEMED" && (
            <Title level={3}>This SIM has already been redeemed</Title>
        )} */}
        { isJustRedeemed && (<>
          <Flex vertical gap={2} justify="center">
            <Title level={3}>Thank you for redeeming</Title>
            <Text>You will receive an email with further details</Text>
          </Flex>
        </>)}
        { !isJustRedeemed && (
          <Form onFinish={handleFinish} style={styles.form}>
            <Form.Item>
              <FormInput
                label="Order Number"
                type="text"
                required
                maxLength={50}
                name="orderNumber"
                value={redemptionData!!.orderId}
              />
            </Form.Item>
            { fields!!.fields.map(field => (
              <Form.Item>
                <FormInput
                  {...field}
                  value={formData[field.name]}
                  onChange={s => handleSetFormData(field.name, s)}
                />
              </Form.Item>

            ))}
            <ConfirmButton htmlType="submit" />
          </Form>
        )}


      </>
      )}
      { redemptionData?.status === "REDEEMED" && (
        <Title level={3}>This SIM has already been redeemed</Title>
      )}


    </Container>
  );
}
